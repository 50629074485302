<template>
  <div class="px-5" id="page-wait-books">
    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('BOOK.CONFIRM_DELETE')"
      ref="deleteBookConfirm"
      type="success"
      @confirm="onDeleteBookConfirm"
    />

    <input hidden type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
    <BookTable
      :books="local_books"
      :showError="true"
      type="wait"
      @selectBookClicked="selectBook"
      @deleteBookClicked="deleteBookClicked"
    />


  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BookTable from '@/view/pages/ml/book/BookTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'bookswait',
  props: ['books'],
  emits: ['selectBook'],
  components: {
    BookTable,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentBookId'])
  },
  mounted() {
    if (this.books) {
      this.local_books = [ ...this.books ]
    }
    // this.loadBooks();
  },
  data() {
    return {
      form: {
        id: '',
        from: '',
        to: '',
        name: ''
      },
      loaded: false,
      creating: false,
      editing: false,
      local_books: [],
      toDelete: null
    };
  },
  watch: {
    books: {
      deep: true,
      handler(val) {
        this.local_books = [ ...val ]
      }
    },
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadBooks();
      }
    }
  },
  methods: {
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }

      this.loadBooks();
    },
    addField(value, fieldType) {
      fieldType.push({ debit: 0, credit: 0 });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    submitBook(e) {
      e.preventDefault();

      this.updateBook();
    },
    selectBook(book) {
      this.$emit('selectBook', book);
    },
    deleteBookClicked(book_id) {
      this.toDelete = book_id;
      this.$refs['deleteBookConfirm'].show();
    },
    onDeleteBookConfirm() {
      this.deleteBook(this.toDelete);
      this.toDelete = null;
    },
    updateBook() {
      if (this.creating) {
        axios
          .post('/book', this.form)
          .then(res => {
            if (res.status === 201) {
              this.creating = false;
              this.form = res.data.book;
              this.toastr('success', this.$t('COMMON.OK'), 'Verifikat skapades');
              this.loadBooks();
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa verifikat');
          });
      } else {
        axios
          .put('/book', this.form)
          .then(res => {
            if (res.status === 204) {
              this.creating = false;
              this.form = res.data.book;
              this.toastr('success', this.$t('COMMON.OK'), 'Verifikatet uppdaterades');
              this.loadBooks();
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera verifikatet');
          });
      }
    },
    createBook() {
      this.creating = true;
      this.editing = true;

      this.scrollToEditBook();

      this.form.company_id = this.currentCompanyId;
      this.form.book_id = this.currentBookId;
    },
    deleteBook(book_id) {
      axios
        .delete(`/book/${book_id}`)
        .then(res => {
          if (res.status === 204) {
            this.local_books = this.local_books.filter(item => item.book_id !== book_id);

            this.creating = false;
            this.editing = false;

            this.toastr('success', this.$t('COMMON.OK'), 'Verifikatet togs bort');
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort verifikatet');
          }

          // this.loadBooks();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort verifikatet');
        });
    },
    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },
    async loadBooks() {
      this.local_books = [];
      axios
        .get(`/company?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.local_books = res.data.company.books;

            this.loaded = true;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista verifikat');
        });
    },
    scrollToEditBook() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('edit-live-book-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();
    },
    selectFile() {
      this.$refs.file.click();
    },
    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('ladok_id', this.form.ladok_id);
      formData.append('file_type', 'FILE_CSV');
      formData.append('form_ladok_id', this.form.ladok_id);
      formData.append('company_id', this.currentCompanyId);

      axios
      .post( '/fileupload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(res => {

        axios
        .post('/ladok/upload',
          {
            ladok_id: this.form.ladok_id,
            file_id: res.data.fileobjs[0].file_id
          }
        )
        .then(ladokres => {
          this.toastr('success', this.$t('COMMON.OK'), 'LADOK status uppdaterades');
          this.form.ended_at = ladokres.data.ladok.ended_at;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera LADOK körningens status');
        });
      })
      .catch( err => {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda upp fil');
      });
    },

  }
};
</script>
